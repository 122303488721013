import { React, useCallback } from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { ConfirmationModal } from "./ConfirmationModal";
import { uploadImage } from "../logic/web";
import { getParticipantData, registerNotification } from "../logic/database";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { getEventViewUrl } from "../logic/helper";
import { InternalImageUpload } from "../logic/exchange/internalImageUpload";
import { isArray } from "../logic/validation";

export const IDENTIFIER = "image-upload";
export const IDENTIFIER_MULTI = "image-multi-upload";

function validateData(taskReferences) {
    if (!isArray(taskReferences)) {
        throw new Error("Invalid Payload (not an array)");
    }
    if (taskReferences.length <= 0) {
        throw new Error("Invalid Payload (empty array)");
    }
    let eventId = ""; // Expects that all items use the same event ID
    for (let taskIndex = 0; taskIndex < taskReferences.length; taskIndex++) {
        let taskObj = taskReferences[taskIndex];
        if (!(taskObj instanceof InternalImageUpload)) {
            taskObj = new InternalImageUpload().applyExport(taskReferences[taskIndex]);
            taskReferences[taskIndex] = taskObj;
        }
        if (eventId.length <= 0) {
            eventId = taskObj.getEventId();
        }
        if (eventId !== taskObj.getEventId()) {
            throw new Error("Invalid Payload (mixed items)");
        }
    }
    if (eventId.length <= 0) {
        throw new Error("Invalid Payload (event id is not set)");
    }
    return [taskReferences, eventId];
}

ImageUploadConfirmation.propTypes = {
    data: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
}
export function ImageUploadConfirmation({ data, onClose }) {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [completedUploads, setCompletedUploads] = useState([]);
    const [confirmationModalIsVisible, setConfirmationModalIsVisible] = useState(true);
    const isMultiUpload = (data[0] === IDENTIFIER_MULTI);

    const [taskReferences, eventId] = validateData(data[1]);

    const handleUpload = useCallback(async () => {
        const participantObj = await getParticipantData();

        const uploadTasks = []
        for (let taskReferenceIndex = 0; taskReferenceIndex < taskReferences.length; taskReferenceIndex++) {
            try {
                const taskReference = taskReferences[taskReferenceIndex];
                if (completedUploads.includes(taskReference)) {
                    // Skip already completed uploads
                    continue;
                }
                const participantEvent = participantObj.getJoinedEventById(taskReference.getEventId());
                const participanTask = participantEvent.getTaskById(taskReference.getTaskId());
                uploadTasks.push(uploadImage(taskReference, participanTask.getImage()));
            } catch (error) {
                // Partial upload error? Should be handled if there was a problem
            }
        }
        const uploadResult = await Promise.allSettled(uploadTasks);
        const fulfilled = uploadResult.filter((item) => { return item.status === 'fulfilled' });

        if (isMultiUpload) {
            const totalCompletedUploads = (completedUploads.length + fulfilled.length);
            if (totalCompletedUploads === taskReferences.length) { // No errors, all uploaded
                await registerNotification({
                    id: `${eventId}-multi-upload-confirmation`,
                    title: "Images were uploaded",
                    text: "Looks good, your task images were uploaded.",
                    delay: 5000,
                });
                setConfirmationModalIsVisible(false);
            } else if (fulfilled.length > 0) { // Some errors, some success
                await registerNotification({
                    id: `${eventId}-multi-upload-partial-failed-user`,
                    variant: "danger",
                    title: "Image upload partially failed",
                    text: "Yikes, we were not able to upload all images. If you want to try again, click on upload again.",
                    delay: 10000,
                });
                setCompletedUploads(fulfilled.map((item) => { return item.value }));
                setLoading(false);
            } else { // No success
                await registerNotification({
                    id: `${eventId}-multi-upload-failed-user`,
                    variant: "danger",
                    title: "Image upload failed",
                    text: "Yikes, the upload of all images failed. If you want to try again, click on upload again.",
                    delay: 10000,
                });
                setLoading(false);
            }
        } else { // Single Upload
            const taskId = taskReferences[0].getTaskId();
            if (fulfilled.length > 0) {
                await registerNotification({
                    id: `${taskId}-upload-confirmation`,
                    title: "Image was uploaded",
                    text: "Looks good, your task image was uploaded.",
                    delay: 5000,
                });
                setConfirmationModalIsVisible(false);
            } else {
                await registerNotification({
                    id: `${taskId}-upload-failed-user`,
                    variant: "danger",
                    title: "Image upload failed",
                    text: "Yikes, the upload of an image failed. If you want to try again, click on upload again.",
                    delay: 10000,
                });
                setLoading(false);
            }
        }
    }, [completedUploads, eventId, isMultiUpload, taskReferences]);
    const handleUploadCompleted = useCallback(() => {
        handleUpload().then(() => {
            setLoading(false);
            navigate(getEventViewUrl(eventId)); // Trigger reload for notifications
        });
    }, [eventId, navigate, handleUpload])
    useEffect(() => {
        if (isLoading) {
            handleUploadCompleted();
        }
    }, [handleUploadCompleted, isLoading])

    const handleClose = async (confirmed) => {
        if (confirmed && !isLoading) {
            setLoading(true);
        }
        if (!confirmed) {
            onClose();
            setConfirmationModalIsVisible(false);
        }
    }

    return (<ConfirmationModal title="Upload Image" visible={confirmationModalIsVisible} loading={isLoading} onClose={handleClose} text={
        <Container>
            <Row>
                <Col>
                    Would you like to upload the task image and share it with the event organizers?
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className="text-muted">When you upload an image, you are confirming that you have the permission of everyone in the image to share it and that it is not copyrighted material.</small>
                </Col>
            </Row>
        </Container>} />)
}