import { React } from "react";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { Form } from "react-bootstrap";

const variants = {
    'small': {
        'size': 100,
        'logoSize': 16,
        'logoSource': "/logo32.png",
    },
    'medium': {
        'size': 200,
        'logoSize': 32,
        'logoSource': "/logo32.png",
    },
    'large': {
        'size': 300,
        'logoSize': 64,
        'logoSource': "/logo64.png",
    }
}

CustomQRSVG.propTypes = {
    value: PropTypes.string.isRequired,
    marginSize: PropTypes.number.isRequired,
    title: PropTypes.string,
    variant: PropTypes.string,
}
export function CustomQRSVG({ value, marginSize, title = "", variant = "medium" }) {
    /*
    Alternative using ranges
    const [selectedVariant, setSelectedVariant] = useState(variant);

    const imageSettings = {
        src: variants[selectedVariant].logoSource,
        width: variants[selectedVariant].logoSize,
        height: variants[selectedVariant].logoSize
    };
    return (
        <div style={{ maxWidth: `${variants[selectedVariant].size}px` }}>
            <Form.Range min={0} max={Object.entries(variants).length - 1} value={Object.keys(variants).indexOf(selectedVariant)} onChange={(e) => { setSelectedVariant(Object.keys(variants)[e.target.value]); }} />
            <QRCodeSVG value={value} marginSize={marginSize} size={variants[selectedVariant].size} title={title} imageSettings={imageSettings} />
        </div>
    );
    */

    const [selectedVariant, setSelectedVariant] = useState(variant);
    const options = Object.keys(variants).map((item) => {
        return <option key={item}>{item}</option>
    })

    const imageSettings = {
        src: variants[selectedVariant].logoSource,
        width: variants[selectedVariant].logoSize,
        height: variants[selectedVariant].logoSize
    };
    return (
        <div style={{ maxWidth: `${variants[selectedVariant].size}px` }}>
            <Form.Select size="sm" onChange={(e) => { setSelectedVariant(e.target.value); }} defaultValue={selectedVariant}>
                {options}
            </Form.Select>
            <QRCodeSVG value={value} marginSize={marginSize} size={variants[selectedVariant].size} title={title} imageSettings={imageSettings} />
        </div>
    );
}