import { React } from "react";
import PropTypes from "prop-types";
import { Scanner } from '@yudiel/react-qr-scanner';

CustomQRReader.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
}
export function CustomQRReader({ onUpdate, onError }) {
    return <Scanner onScan={(result) => onUpdate(result)} onError={(error) => onError(error)} formats={['qr_code']} components={{ audio: false, zoom: false, finder: false }} styles={{ }} />;
}