import { React } from "react";

export function Privacy() {
    return (
        <div>
            <h4>Privacy Policy</h4>
            <p>
                <strong>Last updated: December 07, 2024</strong>
            </p>

            <p>
                We appreciate your interest in using our service. Data protection is very important to us, as such all data provided is client-side first.
                All data provided will only be stored on your device until specifically notified otherwise.

                From a legal standpoint, we need to make sure that eventualities are covered, but here is the short version:
                Currently, the only user-generated data sent from your device to our servers are task-related images.
                These images are stored locally by default. Once you confirm the completion of the task with the Event Manager, you will receive a confirmation to approve the upload of the image.
                When you approve the image upload, you must ensure that everyone on the image has agreed to share the image with us (EventQuest) and the Event Manager.

                The use of EventQuest&apos;s website is possible without providing personal data;
                However, some of our services require the processing of personal data.
                If the processing of personal data is necessary and there is no legal basis for such processing, we generally obtain the consent of the data subject.
            </p>

            <p>
                The processing of personal data, such as a data subject&apos;s name, address, email address or telephone number, will always be in accordance with the General Data Protection Regulation (GDPR),
                and in accordance with the country-specific data protection provisions applicable to EventQuest.

                With this privacy statement, our company would like to inform the general public about the nature, scope and purpose of the personal data we collect and process,
                use and process. In addition, this Privacy Policy is intended to inform individuals of the rights to which they are entitled.
            </p>

            <p>
                As the controller, EventQuest has implemented technical and organizational measures to ensure the most complete protection of personal data processed through this website.
                However, Internet-based data transmissions are inherently subject to security vulnerabilities, and absolute protection cannot be guaranteed.
            </p>

            <h4>1. Definitions</h4>
            <p>
                EventQuest&apos;s privacy policy is based on the terms used by the European legislator for the adoption of the General Data Protection Regulation (GDPR).
                Our privacy policy should be readable and understandable to the general public, as well as to our customers and business partners. To ensure this,
                we would first like to explain the terminology used.
            </p>

            <p>In this Privacy Policy, we use the following terms, among others:</p>

            <ul style={{ listStyle: "none" }}>
                <li>
                    <h4>a) Personal data</h4>
                    <p>
                        Personal data is any information relating to an identified or identifiable natural person (&quot;data subject&quot;).
                        An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name,
                        an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental,
                        economic, cultural or social identity of that natural person.
                    </p>
                </li>
                <li>
                    <h4>b) Data subject</h4>
                    <p>
                        A data subject is any identified or identifiable natural person whose personal data is processed by the data controller.
                    </p>
                </li>
                <li>
                    <h4>c) Processing</h4>
                    <p>
                        Processing is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by
                        by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use
                        disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
                    </p>
                </li>
                <li>
                    <h4>d) Restriction of processing</h4>
                    <p>
                        Restriction of processing is the marking of stored personal data with the aim of limiting its processing in the future.
                    </p>
                </li>
                <li>
                    <h4>e) Profiling</h4>
                    <p>
                        Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person,
                        in particular to analyse or predict aspects concerning that natural person&apos;s performance at work, economic situation, health, personal preferences, interests, reliability,
                        behaviour, location or movements.
                    </p>
                </li>
                <li>
                    <h4>f) Pseudonymisation</h4>
                    <p>
                        Pseudonymization is the processing of personal data in such a way that the personal data can no longer be associated with a specific data subject without the use of additional information.
                        additional information, provided that such additional information is kept separate and subject to technical and organizational measures to ensure that the personal data cannot be associated
                        with an identified not be associated with an identified or identifiable natural person.
                    </p>
                </li>
                <li>
                    <h4>g) Controller or controller responsible for the processing</h4>
                    <p>
                        The data controller is the natural or legal person, public authority, agency or other body that
                        who, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and
                        Where the purposes and means of such processing are determined by Union or Member State law, Union or Member State law may provide
                        for the identification of the controller or the specific criteria for his or her designation.
                    </p>
                </li>
                <li>
                    <h4>h) Processor</h4>
                    <p>
                        A processor is a natural or legal person, public authority, agency or other body that processes personal data on behalf of the controller.
                    </p>
                </li>
                <li>
                    <h4>i) Recipient</h4>
                    <p>
                        Recipient is a natural or legal person, public authority, agency or other body to which personal data is
                        to whom the personal data are disclosed, whether or not it is a third party. However, public authorities which receive personal data
                        Union or Member State law in the course of a specific investigation shall not be considered as recipients;
                        the processing of such data by those authorities shall be in accordance with the applicable data protection rules, depending on the purposes of the processing.
                    </p>
                </li>
                <li>
                    <h4>j) Third party</h4>
                    <p>
                        Third party is a natural or legal person, public authority, agency or body other than the data subject, the controller, the processor and persons who are
                        under the direct authority of the controller or processor, are authorized to process personal data.
                    </p>
                </li>
                <li>
                    <h4>k) Consent</h4>
                    <p>
                        Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject&apos;s wishes by which they, by a statement or by a clear
                        affirmative action, to the processing of personal data relating to them.
                    </p>
                </li>
                <li>
                    <h4>k) Hosting</h4>
                    <p>
                        Every website on the Internet needs to be hosted. There are several companies (hosts) that provide this service. This service is also called hosting.
                    </p>
                </li>
            </ul>

            <h4>2. Cookies</h4>
            <p>
                EventQuest website doesn&apos;t use cookies. Cookies are text files that are stored on a computer system via an Internet browser and are often used to track users.
            </p>

            <h4>3. Collection of general data and information</h4>
            <p>
                EventQuest&apos;s host may collects a range of general data and information whenever a human being or an automated system accesses the website.
                This general information is stored in server log files.
                Collected may be
                (1) the browser types and versions used,
                (2) the operating system used by the accessing system,
                (3) the website from which an accessing system reaches our website (so-called referrers),
                (4) the sub-websites,
                (5) the date and time of access to the Internet site,
                (6) an Internet protocol address (IP address),
                (7) the Internet service provider of the accessing system, and
                (8) any other similar data and information that may be used in the event of attacks on our information technology systems.
            </p>

            <p>
                When using these general data and information, the hoster does not draw any conclusions about the data subject.
                Rather, this information is needed to
                (1) deliver the content of our website correctly,
                (2) optimize the content of our website,
                (3) ensure the long-term viability of our information technology systems and website technology, and
                (4) provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack.
                Therefore, the host may analyzes anonymously collected data and information statistically, with the aim of increasing the data protection and data security of our enterprise.
                data security, and to ensure an optimal level of protection for the personal data we process.
                Anonymous server log file data is stored separately from any personally identifiable information provided by a data subject.
            </p>

            <h4>4. Contact possibility via the website</h4>
            <p>
                EventQuest web site contains information that allows you to contact us quickly and easily,
                as well as direct communication with us, including a general electronic mail address (e-mail address).
                If a data subject contacts the data controller via e-mail or via a contact form, the personal data provided by the data subject will be
                automatically stored. Such personal data, voluntarily provided by the data subject to the data controller, will be stored
                for the purpose of processing or contacting the data subject. There is no transfer of this personal data to third parties.
            </p>

            <h4>5. Routine erasure and blocking of personal data</h4>
            <p>
                The controller shall process and store the personal data of the data subject
                the data subject for no longer than is necessary to achieve the purpose of the retention or as may be required by the European legislator or any other legislator in laws or
                legislator in laws or regulations to which the controller is subject.
            </p>

            <p>
                If the purpose of storage no longer applies or if a retention period prescribed by the European legislator or another competent legislator has expired, the personal data will be
                or other competent legislator, the personal data will be routinely blocked or deleted in accordance with legal requirements.
            </p>

            <h4>6. Rights of the data subject</h4>
            <ul style={{ listStyle: "none" }}>
                <li>
                    <h4>a) Right of confirmation</h4>
                    <p>
                        Every data subject has the right, granted by the European legislator, to obtain from the controller
                        confirmation as to whether or not personal data concerning them are being processed.
                        If a data subject wishes to exercise this right of confirmation, they may contact the controller at any time.
                    </p>
                </li>
                <li>
                    <h4>b) Right of access</h4>
                    <p>
                        Every data subject shall have the right, granted by the European
                        to obtain from the data controller, at any time and free of charge, information
                        stored about them and to obtain a copy of that information. In addition, the European directives and regulations
                        grant the data subject access to the following information:
                    </p>

                    <ul style={{ listStyle: "none" }}>
                        <li>the purposes of the processing;</li>
                        <li>the categories of personal data concerned;</li>
                        <li>the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organizations;</li>
                        <li>where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;</li>
                        <li>the existence of the right to request from the controller rectification or erasure of personal data, or restriction of processing of personal data concerning the data subject,
                            or to object to such processing;</li>
                        <li>the existence of the right to lodge a complaint with a supervisory authority;</li>
                        <li>where the personal data are not collected from the data subject, any available information as to their source;</li>
                        <li>the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) of the GDPR and, at least in those cases,
                            meaningful information about the logic involved, as well as the significance and envisaged consequences of such processing for the data subject.</li>

                    </ul>
                    <p>
                        In addition, the data subject shall have the right to obtain information as to whether personal data are being
                        to a third country or to an international organization. If so, the data subject shall
                        have the right to be informed of the appropriate safeguards relating to the transfer.
                    </p>

                    <p>
                        If a data subject wishes to exercise this right, they may, at any time, contact the data controller.
                    </p>
                </li>
                <li>
                    <h4>c) Right to rectification</h4>
                    <p>
                        Every data subject shall have the right granted by the European legislator to obtain from the controller without undue delay the rectification of
                        without undue delay, the rectification of inaccurate personal data concerning them. Having regard to the purposes of the processing
                        the data subject shall have the right to have incomplete personal data completed, including by means of a supplementary statement.
                    </p>

                    <p>
                        If a data subject wishes to exercise the right of rectification, they may at any time contact the data controller.
                    </p>
                </li>
                <li>
                    <h4>d) Right to be forgotten</h4>
                    <p>
                        Every data subject has the right, granted by the European legislator, to obtain from the controller
                        the controller to delete personal data concerning them without undue delay,
                        and the controller shall have the obligation to erase personal data without undue delay if one of the following
                        grounds, unless the processing is no longer necessary:
                    </p>

                    <ul style={{ listStyle: "none" }}>
                        <li>The personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed.</li>
                        <li>The data subject withdraws consent to which the processing is based according to point (a) of Article 6(1) of the GDPR, or point (a) of Article 9(2) of the GDPR, and where there is no other legal ground for the processing.</li>
                        <li>The data subject objects to the processing pursuant to Article 21(1) of the GDPR and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) of the GDPR. </li>
                        <li>The personal data have been unlawfully processed.</li>
                        <li>The personal data must be erased for compliance with a legal obligation in Union or Member State law to which the controller is subject.</li>
                        <li>The personal data have been collected in relation to the offer of information society services referred to in Article 8(1) of the GDPR.</li>

                    </ul>
                    <p>
                        If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by EventQuest, they may, at any time, contact the controller.
                        EventQuest shall ensure that the request for erasure is promptly complied with.
                    </p>

                    <p>
                        Where the controller has made personal data public and is obliged to erase them pursuant to Article 17(1)
                        to erase the personal data, the controller shall take reasonable steps, including technical measures, having regard to available technology and the cost of implementation,
                        including technical measures, to inform other controllers who process the personal data that the data subject has requested that they erase any links to, or copies or replicas of, those personal data.
                        personal data to the extent that processing is not required. An EventQuest employee will take the necessary steps in individual cases.
                    </p>
                </li>
                <li>
                    <h4>e) Right of restriction of processing</h4>
                    <p>
                        Each data subject shall have the right granted by the European legislator to obtain from the controller restriction of processing where one of the following applies:
                    </p>

                    <ul style={{ listStyle: "none" }}>
                        <li>The accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data. </li>
                        <li>The processing is unlawful and the data subject opposes the erasure of the personal data and requests instead the restriction of their use instead.</li>
                        <li>The controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defence of legal claims.</li>
                        <li>The data subject has objected to processing pursuant to Article 21(1) of the GDPR pending the verification whether the legitimate grounds of the controller override those of the data subject.</li>
                    </ul>
                    <p>
                        If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of
                        the processing of personal data stored by EventQuest, they may at any time contact the controller. The employee of EventQuest will arrange the restriction of the processing.
                    </p>
                </li>
                <li><h4>f) Right to data portability</h4>
                    <p>
                        Each data subject shall have the right granted by the European legislator, to receive the personal data concerning them,
                        which was provided to a controller, in a structured, commonly used and machine-readable format. they shall have the right to
                        transmit those data to another controller without hindrance from the controller to which the personal data have been provided, as long as the processing is based on consent pursuant to
                        point (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract pursuant to point (b) of Article 6(1) of the GDPR, and the processing is carried out by automated
                        means, as long as the processing is not necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.
                    </p>

                    <p>
                        Furthermore, in exercising his or her right to data portability pursuant to Article 20(1) of
                        the GDPR, the data subject shall have the right to have personal data transmitted directly from one controller to another,
                        where technically feasible and when doing so does not adversely affect the rights and freedoms of others.
                    </p>

                    <p>
                        In order to assert the right to data portability, the data subject may at any time contact EventQuest.
                    </p>

                </li>
                <li>
                    <h4>g) Right to object</h4>
                    <p>
                        Each data subject shall have the right granted by the European legislator to object,
                        on grounds relating to his or her particular situation, at any time, to processing of personal data concerning them,
                        which is based on point (e) or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these provisions.
                    </p>

                    <p>
                        EventQuest shall no longer process the personal data in the event of the objection,
                        unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject,
                        or for the establishment, exercise or defence of legal claims.
                    </p>

                    <p>
                        If EventQuest processes personal data for direct marketing purposes,
                        the data subject shall have the right to object at any time to processing of personal data concerning them for such marketing.
                        This applies to profiling to the extent that it is related to such direct marketing. If the data subject objects to EventQuest to the processing for direct marketing purposes,
                        EventQuest will no longer process the personal data for these purposes.
                    </p>

                    <p>
                        In addition, the data subject has the right, on grounds relating to his or her particular situation,
                        to object to processing of personal data concerning them by EventQuest for scientific or historical research purposes,
                        or for statistical purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the performance of a task carried out for reasons of public interest.
                    </p>

                    <p>
                        In order to exercise the right to object, the data subject may contact EventQuest.
                        In addition, the data subject is free in the context of the use of information society services, and notwithstanding Directive 2002/58/EC,
                        to use his or her right to object by automated means using technical specifications.
                    </p>
                </li>
                <li>
                    <h4>h) Automated individual decision-making, including profiling</h4>
                    <p>
                        Each data subject shall have the right granted by the European legislator not to be subject
                        to a decision based solely on automated processing, including profiling, which produces legal effects
                        concerning them, or similarly significantly affects them, as long as the decision (1) is not is necessary for entering into, or the performance of,
                        a contract between the data subject and a data controller, or (2) is not authorised by Union or Member State law to which the controller is subject
                        and which also lays down suitable measures to safeguard the data subject&apos;s rights and freedoms and legitimate interests, or (3) is not based on the data subject&apos;s explicit consent.
                    </p>

                    <p>
                        If the decision (1) is necessary for entering into, or the performance of,
                        a contract between the data subject and a data controller, or (2) it is based on the data subject&apos;s explicit consent,
                        EventQuest shall implement suitable measures to safeguard the data subject&apos;s rights and freedoms and legitimate interests, at least the right to
                        obtain human intervention on the part of the controller, to express his or her point of view and contest the decision.
                    </p>

                    <p>
                        If the data subject wishes to exercise the rights concerning automated individual decision-making, they may, at any time, contact EventQuest.
                    </p>

                </li>
                <li>
                    <h4>i) Right to withdraw data protection consent </h4>
                    <p>
                        Each data subject shall have the right granted by the European legislator to withdraw his or her consent to processing of his or her personal data at any time.
                    </p>

                    <p>
                        If the data subject wishes to exercise the right to withdraw the consent, they may, at any time, contact EventQuest.
                    </p>
                </li>
            </ul>

            <h4>7. Legal basis for the processing </h4>
            <p>
                Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain consent for a specific processing purpose.
                If the processing of personal data is necessary for the performance of a contract to which the data subject is party, as is the case, for example,
                when processing operations are necessary for the supply of goods or to provide any other service, the processing is based on Article 6(1) lit. b GDPR.
                The same applies to such processing operations which are necessary for carrying out pre-contractual measures, for example in the case of inquiries concerning our products or services.
                Is our company subject to a legal obligation by which processing of personal data is required, such as for the fulfillment of tax obligations, the processing is based on Art. 6(1) lit. c GDPR.
                In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or of another natural person. This would be the case,
                for example, if a visitor were injured in our company and his name, age, health insurance data or other vital information would have to be passed on to a doctor, hospital or
                other third party. Then the processing would be based on Art. 6(1) lit. d GDPR.
                Finally, processing operations could be based on Article 6(1) lit. f GDPR. This legal basis is used for processing operations which are not covered by any of the abovementioned
                legal grounds, if processing is necessary for the purposes of the legitimate interests pursued by our company or by a third party, except where such interests are overridden by the
                interests or fundamental rights and freedoms of the data subject which require protection of personal data. Such processing operations are particularly permissible because they have
                been specifically mentioned by the European legislator. He considered that a legitimate interest could be assumed if the data subject is a client of the controller (Recital 47 Sentence 2 GDPR).
            </p>

            <h4>8. The legitimate interests pursued by the controller or by a third party</h4>
            <p>
                Where the processing of personal data is based on Article 6(1) lit. f GDPR our legitimate interest is to carry out our business in favor of
                the well-being of all our employees and the shareholders.
            </p>

            <h4>9. Period for which the personal data will be stored</h4>
            <p>
                The criteria used to determine the period of storage of personal data is the respective
                statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as
                long as it is no longer necessary for the fulfillment of the contract or the initiation of a contract.
            </p>

            <h4>10. Provision of personal data as statutory or contractual requirement; Requirement necessary to enter into a contract; Obligation of the data subject to provide the personal data; possible consequences of failure to provide such data </h4>
            <p>
                We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner).

                Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with them. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded.

                Before personal data is provided by the data subject, the data subject must contact any employee. The employee clarifies to the data subject whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.
            </p>

            <h4>11. Existence of automated decision-making</h4>
            <p>
                We do not use automatic decision-making or profiling.
            </p>

        </div>
    )
}