import { React } from "react";
import PropTypes from "prop-types";
import { useRouteError } from "react-router-dom";
import { FullPageContainer } from "./components/FullPageContainer";
import { resetDB } from "./logic/database";

ErrorPage.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    customError: PropTypes.any,
}
export function ErrorPage({ title = "Oops!", description = "Sorry, an unexpected error has occurred.", customError }) {
    const error = useRouteError() || customError;

    return (
        <FullPageContainer>
            <div>
                <h1 className="pb-3">
                    {title}
                </h1>
                <div className="pb-2">
                    {description}
                </div>
                <div>
                    <small className="text-muted">Error: {error.statusText || error.message}</small>
                </div>
                <div>
                    <a href="/" style={{ paddingRight: "1em" }} className="">Open homepage</a>
                    <a href="/" onClick={() => { resetDB() }}>Reset database</a>
                </div>
            </div>
        </FullPageContainer>
    );
}