import { React } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

RoundIconButton.propTypes = {
    children: PropTypes.any,
    onClickHandler: PropTypes.func.isRequired,
    variant: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
}
export function RoundIconButton({ children, onClickHandler, variant = "primary", label = "", type }) {
    return (
        <Button variant={variant} aria-label={label} className="rounded-circle" style={{ paddingRight: "10px", paddingLeft: "10px" }} onClick={(e) => onClickHandler ? onClickHandler(e) : () => { }} type={type}>
            {children}
        </Button>
    );
}