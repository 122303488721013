import { React } from "react";
import PropTypes from "prop-types";
import { Button, Stack } from "react-bootstrap";

TokenActionBar.propTypes = {
    totalTokens: PropTypes.number.isRequired,
    onCreateClick: PropTypes.func.isRequired,
    onDeleteAllClick: PropTypes.func.isRequired,
}
export function TokenActionBar({ totalTokens, onCreateClick, onDeleteAllClick }) {
    let hideDeleteButton = "";
    if (totalTokens <= 0) {
        hideDeleteButton = "d-none";
    }
    return (
        <Stack gap={2} direction="horizontal" className="mb-3">
            <Button variant="primary" onClick={() => onCreateClick()}><i className="bi bi-plus-circle"></i> Add a token</Button>
            <Button variant="danger" onClick={() => onDeleteAllClick()} className={hideDeleteButton} ><i className="bi bi-trash"></i> Delete all tokens</Button>
        </Stack>
    )
}  