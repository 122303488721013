import { getParticipantData, registerNotification, updateParticipantData } from "./database";
import { ParticipantEvent } from "./participant/participantEvent";
import { getEventById } from "./web";
import { EventDefinition } from "./event/eventDefinition";
import { ParticipantEventTask } from "./participant/participantEventTask";
import { ParticipantEventStatus } from "./participant/participantEventStatus";
import { ParticipantEventToken } from "./participant/participantEventToken";
import { ParticipantEventReward } from "./participant/participantEventReward";
import { IDENTIFIER as ImageUploadIdentifier , IDENTIFIER_MULTI as ImageMultiUploadIdentifier } from "../components/ImageUploadConfirmation";
import { InternalImageUpload } from "./exchange/internalImageUpload";


export async function joinAction({ params }) {
    // Create a new event reference for the current user
    const participant = await getParticipantData();
    const participantEvent = new ParticipantEvent();
    const eventText = await getEventById(params.eventId);
    const eventObj = new EventDefinition().applyExport(eventText);
    participantEvent.setEvent(eventObj);
    participant.addJoinedEvent(participantEvent);

    // Automatically start all tasks and tokens which were directly available
    eventObj.getTasks().forEach((task) => {
        if (task.isDirectlyAvailable()) {
            participantEvent.addTask(startTask(task));
        }
    });
    eventObj.getTokens().forEach((token) => {
        if (token.isDirectlyAvailable()) {
            participantEvent.addToken(startToken(token));
        }
    });

    await updateParticipantData(participant);

    return null;
}

export async function leaveAction({ params }) {
    // Delete all stored data for the affected event
    const participant = await getParticipantData();
    participant.removeJoinedEventById(params.eventId);
    await updateParticipantData(participant);

    return null;
}

export async function updateEventAction({ params }) {
    try {
        const participant = await getParticipantData();
        const participantEvent = participant.getJoinedEventById(params.eventId);

        const updatedEventData = await getEventById(params.eventId);
        const updatedEvent = new EventDefinition().applyExport(updatedEventData);

        if (participantEvent.getEvent().wasUpdated(updatedEvent.getVersion())) {
            participantEvent.setEvent(updatedEvent);

            updatedEvent.getTasks().forEach((task) => {
                if (task.isDirectlyAvailable() && !participantEvent.knownTask(task)) {
                    participantEvent.addTask(startTask(task));
                }
            });

            updatedEvent.getTokens().forEach((token) => {
                if (token.isDirectlyAvailable() && !participantEvent.knownToken(token)) {
                    participantEvent.addToken(startToken(token));
                }
            });

            await updateParticipantData(participant);

            await registerNotification({
                id: `${params.eventId}-updated`,
                title: "Event was updated",
                text: "Looks like there was an update available for this event! We just installed it.",
                delay: 5000,
            });

            return true;
        }

        if (params.nothingOnNoUpdate) {
            return false;
        }

        await registerNotification({
            id: `${params.eventId}-not-updated`,
            variant: "secondary",
            title: "Event was not updated",
            text: "Looks like there was no new update available for this event.",
            delay: 5000,
        });
    } catch (error) {
        await registerNotification({
            id: `${params.eventId}-updated-error`,
            variant: "danger",
            title: "Event update failed",
            text: "Oops, looks like it was not possible to update the event.",
            delay: 10000,
        });
    }

    return false;
}

function startTask(task) {
    const participantTask = new ParticipantEventTask();
    participantTask.setStatus(new ParticipantEventStatus());
    participantTask.setActivity(task);

    return participantTask;
}

export async function taskStartAction({ params }) {
    // The user found an task, add the task to their list
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const event = participantEvent.getEvent();
    const task = event.getTaskById(params.taskId);

    participantEvent.addTask(startTask(task));

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.taskId}-started`,
        title: "Task started",
        text: "Good luck and have fun with your new task.",
        delay: 5000,
    });

    return null;
}

export async function taskSetImageAction({ params }) {
    // The user completed a task, and confirmed it
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const participantTask = participantEvent.getTaskById(params.taskId);

    participantTask.setImage(params.image);

    await updateParticipantData(participant);

    return null;
}

export async function eventCompleteAction({ params }){
    // The user completed an event, and wants to confirmed it
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    
    if (!participantEvent.getEvent().isSecret(params.secret)){
        throw new Error("Invalid Secret");
    }

    const participantTasks = participantEvent.getTasks();
    const imageTasks = [];
    for (let taskIndex = 0; taskIndex < participantTasks.length; taskIndex++) {
        const participantTask = participantTasks[taskIndex];

        if (participantTask.getActivity().doesExpectImage() && !participantTask.hasImage()) {
            continue; // Skip
        }
        if (participantTask.getStatus().isFinished()){
            continue; // Skip
        }

        participantTask.getStatus().finish();

        if (participantTask.hasImage()){
            imageTasks.push(new InternalImageUpload().applyExport({ eventId: params.eventId, taskId: participantTask.getId() }))
        }
    }

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.eventId}-completed`,
        title: "Event completed",
        text: "Good job! All matching tasks for this event were marked as completed.",
        delay: 5000,
    });

    if (imageTasks.length > 0) {
        await registerNotification({
            id: `${params.eventId}-image-multi-upload-request`,
            display: false,
            data: [ImageMultiUploadIdentifier, imageTasks]
        });
    }

    return null;
}

export async function taskCompleteAction({ params }) {
    // The user completed a task, and confirmed it
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const participantTask = participantEvent.getTaskById(params.taskId);

    if (!participantTask.getActivity().isSecret(params.secret)) {
        throw new Error("Invalid Secret");
    }

    if (participantTask.getActivity().doesExpectImage() && !participantTask.hasImage()) {
        throw new Error("Image is missing");
    }

    participantTask.getStatus().finish();

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.taskId}-completed`,
        title: "Task completed",
        text: "Good job! The task was marked as completed.",
        delay: 5000,
    });

    if (participantTask.hasImage()) {
        await registerNotification({
            id: `${params.taskId}-image-upload-request`,
            display: false,
            data: [ImageUploadIdentifier, [new InternalImageUpload().applyExport({ eventId: params.eventId, taskId: params.taskId })]]
        });
    }

    return null;
}

export async function taskCancelAction({ params }) {
    // The user completed a task, and confirmed it
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const participantTask = participantEvent.getTaskById(params.taskId);

    participantTask.getStatus().cancel();

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.taskId}-cancel`,
        variant: "warning",
        title: "Task canceled",
        text: "Aw, too bad.",
        delay: 5000,
    });

    return null;
}

function startToken(token) {
    const participantToken = new ParticipantEventToken();
    participantToken.setStatus(new ParticipantEventStatus());
    participantToken.setActivity(token);

    return participantToken;
}

export async function tokenFindAction({ params }) {
    // The user found the activity to find a hidden token
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const event = participantEvent.getEvent();
    const token = event.getTokenById(params.tokenId);

    participantEvent.addToken(startToken(token));

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.tokenId}-find`,
        title: "Token search started",
        text: "Good luck looking for the token.",
        delay: 5000,
    });

    return null;
}

export async function tokenCancelAction({ params }) {
    // The user collected a token in the wild, and completed the activity
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const participantToken = participantEvent.getTokenById(params.tokenId);

    participantToken.getStatus().cancel();

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.tokenId}-cancel`,
        variant: "warning",
        title: "Token search was given up",
        text: "Aw, too bad.",
        delay: 5000,
    });

    return null;
}

export async function tokenCollectManualAction({ params }) {
    if (!params.tokenText || params.tokenText.length <= 0) {
        await registerNotification({
            id: `${params.tokenText}-token-error`,
            variant: "danger",
            title: "Invalid token",
            text: "Looks like the provided token was not correct.",
            delay: 10000,
        });

        throw new Error("Invalid Token");
    }

    try {
        // The user collected a token in the wild, and completed the activity
        const participant = await getParticipantData();
        const participantEvent = participant.getJoinedEventById(params.eventId);
        const token = participantEvent.getTokenByText(params.tokenText);
        const participantToken = participantEvent.getTokenById(token.getId());

        participantToken.getStatus().finish();

        await updateParticipantData(participant);

        await registerNotification({
            id: `${token.getId()}-collected-manual`,
            title: "Token confirmed",
            text: "Good job, the provided token looks correct!",
            delay: 5000,
        });
    } catch (error) {
        await registerNotification({
            id: `${params.tokenText}-token-error`,
            variant: "danger",
            title: "Invalid token",
            text: "Looks like the provided token was not correct.",
            delay: 10000,
        });
    }

    return null;
}


export async function tokenCollectAction({ params }) {
    // The user collected a token in the wild, and completed the activity
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const participantToken = participantEvent.getTokenById(params.tokenId);

    if (!participantToken.getActivity().isSecret(params.secret)) {
        throw new Error("Invalid Secret");
    }

    participantToken.getStatus().finish();

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.tokenId}-collected`,
        title: "Token confirmed",
        text: "Good job, the provided token looks correct!",
        delay: 5000,
    });

    return null;
}

export async function rewardConfirmedAction({ params }) {
    // The user wants to get a reward
    const participant = await getParticipantData();
    const participantEvent = participant.getJoinedEventById(params.eventId);
    const event = participantEvent.getEvent();
    const reward = event.getRewardById(params.rewardId);

    if (!reward.isSecret(params.secret)) {
        throw new Error("Invalid Secret");
    }

    const participantReward = new ParticipantEventReward();
    participantReward.setReward(reward);
    participantReward.collected();

    participantEvent.addReward(participantReward);

    await updateParticipantData(participant);

    await registerNotification({
        id: `${params.rewardId}-confirmed`,
        variant: "primary",
        title: "Reward confirmed",
        text: "Congratulations! You unlocked a reward.",
        delay: 5000,
    });

    return null;
}