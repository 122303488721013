import { React } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Col, Container, Figure, Form, Modal, Row } from "react-bootstrap";
import { imageSelectionProcesser } from "../logic/helper";
import { registerNotification } from "../logic/database";
import { useNavigate } from "react-router-dom";

SelectImageModal.propTypes = {
    imageReference: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}
export function SelectImageModal({ imageReference, visible, title, onClose }) {
    const [image, setImage] = useState("");
    const navigate = useNavigate();

    const onImageSelection = (file) => {
        imageSelectionProcesser(file, "perfect")
            .then((result) => {
                setImage(result);

                registerNotification({
                    id: `image-selected`,
                    title: "Image loaded",
                    text: "The image looks good!",
                    delay: 5000
                }).then(() => {
                    navigate();
                });
            }).catch(() => {
                registerNotification({
                    id: `image-selection-failed`,
                    variant: "danger",
                    title: "Image failed to load",
                    text: "Oops, we have trouble with the selected image.",
                    delay: 10000
                }).then(() => {
                    navigate();
                });
            });
    }

    const onOpen = () => {
        setImage(imageReference);
    }

    const onConfirm = () => {
        onClose(true, image);
    }
    //capture={isAndroid() ? "environment" : undefined} TODO
    return (
        <Modal show={visible} backdrop="static" size="lg" fullscreen="xl-down" onHide={() => onClose(false)} onShow={() => onOpen()}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="image" >
                                <Form.Label>Take/Select an image</Form.Label>
                                <Form.Control type="file" placeholder="Select an image" accept="image/png, image/jpeg" onChange={(e) => onImageSelection(e.target.files[0])} />
                            </Form.Group>
                            <Form.Group className={image ? "" : "d-none"}>
                                <Figure>
                                    <Figure.Image
                                        width={600}
                                        height={400}
                                        alt="Preview Image"
                                        src={image || null}
                                        rounded
                                    />
                                </Figure>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <small className="text-muted">Images are resized and stored only on your device. Uploading them is optional and will be offered after you complete the associated task.</small>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { onConfirm() }}><i className="bi bi-check-lg"></i> Confirm</Button>
                <Button variant="danger" onClick={() => { onClose(true, "") }}><i className="bi bi-trash"></i> Delete</Button>
                <Button variant="secondary" onClick={() => { onClose(false) }}><i className="bi bi-x-lg"></i> Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}