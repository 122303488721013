import { React } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getEventById } from "../logic/web";
import { EventDefinition } from "../logic/event/eventDefinition";
import { ErrorPage } from "../ErrorPage";
import { FullPageContainer } from "../components/FullPageContainer";
import { getParticipantData, hasOpenNotifications } from "../logic/database";
import { useEffect } from "react";
import { Participant } from "../logic/participant/participant";
import { EventCard } from "../components/EventCard";
import { updateEventAction } from "../logic/eventActions";

export async function Loader({ params }) {
    let gerror;
    let participantObj;
    try {
        participantObj = await getParticipantData();
    } catch (error) {
        gerror = error;
    }
    let eventObj;
    try {
        const eventText = await getEventById(params.eventId);
        eventObj = new EventDefinition().applyExport(eventText);
    } catch (error) {
        gerror = new Error("Unknown Event");
    }
    return { eventData: eventObj, participant: participantObj, errorContext: gerror };
}

export function EventPreview() {
    const { eventData, participant, errorContext } = useLoaderData();
    const eventJoined = participant.knownJoinedEvent(eventData);
    const navigate = useNavigate();

    useEffect(() => {
        hasOpenNotifications().then(() => {
            navigate();
        });
    }, [navigate]);

    useEffect(() => {
        if (participant.knownJoinedEvent(eventData) && eventData.isActive()) {
            refreshEventData();
        }
    });

    if (errorContext) {
        return (<ErrorPage customError={errorContext} description="Sorry, looks like the event you attempted to open is not available." />)
    }
    if (!(eventData instanceof EventDefinition)) {
        return (<ErrorPage customError={{ statusText: "Incorrent event object instance." }} description="Sorry, looks like the event you attempted to open is broken." />)
    }
    if (!(participant instanceof Participant)) {
        return (<ErrorPage customError={errorContext} description="Sorry, looks like your profile is broken." />)
    }
    if (!eventData.isActive()) {
        if (eventData.hasEnded()) {
            return (<ErrorPage customError={{ statusText: "Not active (hasEnded)." }} description="Sorry, looks like the event you found is no longer active." />)
        }
        if (eventData.hasStarted()) {
            return (<ErrorPage customError={{ statusText: "Not active (hasStarted)." }} description="Sorry, looks like the event you found is not yet active." />)
        }
    }

    const refreshEventData = () => {
        updateEventAction({ params: { eventId: eventData.getId(), nothingOnNoUpdate: true } }).then((result) => {
            if (result) {
                navigate();
            }
        });
    }

    return (
        <FullPageContainer>
            <EventCard eventJoined={eventJoined} eventData={eventData} />
        </FullPageContainer>
    );
}