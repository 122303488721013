import { React } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

AccessCodeModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}
export function AccessCodeModal({ visible, onClose }) {
    const [accessCode, setAccessCode] = useState("");

    const onEnterKeyDown = (e) => { 
        if (e.key.toLowerCase() === "enter") { 
            onCloseCleanup(accessCode) 
        }
    }
    const onCloseCleanup = (accessCode) => {
        onClose(accessCode);
        setAccessCode("");
    }

    return (
        <Modal show={visible} fullscreen="xl-down" onHide={() => onClose("")} onSubmit={(e) => { e.preventDefault(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Set Access Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="secret">
                        <Form.Label>Access Code</Form.Label>
                        <Form.Control type="password" placeholder="Editor access code" autoFocus value={accessCode} onChange={(e) => setAccessCode(e.target.value)} onKeyDown={(e) => onEnterKeyDown(e) } />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose("")}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onCloseCleanup(accessCode)}>
                    <i className="bi bi-check-lg"></i> Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  