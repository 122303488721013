import { React, useCallback } from "react";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import { getParticipantData, hasOpenNotifications, registerNotification } from "../logic/database";
import { FullPageContainer } from "../components/FullPageContainer";
import { Badge, Button, Card, Stack, Tab, Tabs } from "react-bootstrap";
import { getDurationText, getEventBaseUrl, getLocation, getRelativeUrl, isCameraAvailable } from "../logic/helper";
import { useEffect, useState } from "react";
import { QRReaderModal } from "../components/QRReaderModal";
import { RewardContainer } from "../components/RewardContainer";
import "./EventView.css";
import { QRExportModal } from "../components/QRExportModal";
import { TaskContainer } from "../components/TaskContainer";
import { TokenContainer } from "../components/TokenContainer";
import { taskCancelAction, taskSetImageAction, taskStartAction, tokenCancelAction, tokenCollectManualAction, tokenFindAction, updateEventAction } from "../logic/eventActions";
import { SelectImageModal } from "../components/SelectImageModal";
import { ConfirmationModal } from "../components/ConfirmationModal";

export async function Loader({ params }) {
    let gerror;
    let participantObj;
    try {
        participantObj = await getParticipantData();
    } catch (error) {
        gerror = error;
    }
    let eventObj;
    try {
        eventObj = participantObj.getJoinedEventById(params.eventId);
        if (!eventObj) {
            throw new Error("Unknown Event");
        }
    } catch (error) {
        gerror = error;
    }
    let canUseQr = false;
    try {
        canUseQr = await isCameraAvailable();
    } catch (error) {
        canUseQr = false;
    }
    return { eventId: params.eventId, participantEvent: eventObj, participant: participantObj, errorContext: gerror, canUseQr };
}

export function EventView() {
    const { eventId, participantEvent, errorContext, canUseQr } = useLoaderData();

    const [availabilityTime, setAvailabilityTime] = useState(0);
    const [isAvailable, setIsAvailable] = useState(true);
    const [tabSelection, setTabSelection] = useState("tasks");
    const navigate = useNavigate();

    const [showQrReaderModal, setShowQrReaderModal] = useState(false);
    const [showQRrShareModal, setShowQrShareModal] = useState(false);

    const [showSelectImageModal, setShowSelectImageModal] = useState(false);
    const [imageSelectionTaskId, setImageSelectionTaskId] = useState("");
    const [imageSelectionImage, setImageSelectionImage] = useState("");

    const [showDeleteImageConfirmationModal, setShowDeleteImageConfirmationModal] = useState(false);
    const [imageDeleteTaskId, setImageDeleteTaskId] = useState("");

    const lastChange = participantEvent.getLastChangeType();
    const lastChangeId = (!!lastChange && !!lastChange.reference && !!lastChange.reference.object && !!lastChange.reference.object.getId()) ? lastChange.reference.object.getId() : "";

    useEffect(() => {
        setTabSelection(lastChange.key);
    }, [lastChange.key]);

    useEffect(() => {
        hasOpenNotifications().then(() => {
            navigate();
        });
    }, [navigate]);

    const updateAvailability = useCallback(() => {
        let timeLeft = 0;
        if (participantEvent){
            timeLeft = participantEvent.getEvent().getAvailableFor();
        }        
        setAvailabilityTime(timeLeft);
        if (timeLeft <= 0) {
            setIsAvailable(false);
        } else {
            setTimeout(() => {
                updateAvailability();
            }, 1000 * 30);
        }
    }, [participantEvent])

    useEffect(() => {
        updateAvailability()
    }, [updateAvailability]);

    if (errorContext) {
        // Probably not joined yet. lets see if the user can join from the base path
        return (<Navigate to={getEventBaseUrl(eventId)} replace />);
    }

    const availabilityText = () => {
        if (isAvailable) {
            return (<div className="text-wrap">This event is still active for {getDurationText(availabilityTime)}.</div>);
        }
        return (<div className="text-wrap">This event is no longer active.</div>);
    }

    const onQrCodeRead = (result) => {
        if (result) {
            const readUrl = result.toString();
            if (readUrl.startsWith(getLocation())) {
                try {
                    navigator.vibrate([500, 200]);
                // eslint-disable-next-line no-empty
                } catch (error) {

                }
                const cleanedUrl = getRelativeUrl(readUrl);
                navigate(cleanedUrl);
            } else {
                registerNotification({
                    id: `invalid-qr-code`,
                    variant: "warning",
                    title: "Invalid QR-Code",
                    text: "The scanned QR-Code is not part of EventQuest.",
                    delay: 10000,
                }).then(() => {
                    navigate();
                });
            }
        }
        setShowQrReaderModal(false);
    }
    const onQrCodeError = () => {
        registerNotification({
            id: `qr-code-error`,
            variant: "danger",
            title: "QR-Code Error",
            text: "Oops, there was an error encountered while reading the provided QR-Code.",
            delay: 10000,
        }).then(() => {
            navigate();
        });
    }
    const onQrShareClosed = () => {
        setShowQrShareModal(false);
    }
    const onExitButton = () => {
        navigate("/");
    }
    const onUpdateButton = () => {
        updateEventAction({ params: { eventId: participantEvent.getId() } }).then(() => {
            setIsAvailable(true);
            navigate();
        });
    }
    const onShareButton = () => {
        setShowQrShareModal(true);
    }
    const onQrScanButton = () => {
        setShowQrReaderModal(true);
    }

    const onTaskDeletePicture = (taskId) => {
        setImageDeleteTaskId(taskId);
        setShowDeleteImageConfirmationModal(true);
    }
    const onTaskDeletePictureConfirmed = (confirmed) => {
        setShowDeleteImageConfirmationModal(false);
        if (confirmed) {
            taskSetImageAction({ params: { eventId: eventId, taskId: imageDeleteTaskId, image: "" } }).then(() => {
                navigate(); // required for update to be registered
            });
        }
    }
    const onTaskSelectPicture = (taskId) => {
        setImageSelectionTaskId(taskId);
        setImageSelectionImage(participantEvent.getTaskById(taskId).getImage());
        setShowSelectImageModal(true);
    }
    const onSelectImageModalClosed = (save, image) => {
        setShowSelectImageModal(false);
        if (save) {
            taskSetImageAction({ params: { eventId: eventId, taskId: imageSelectionTaskId, image: image } }).then(() => {
                navigate(); // required for update to be registered
            });
        }
    }
    const onTaskStart = (taskId) => {
        taskStartAction({ params: { eventId: eventId, taskId: taskId } }).then(() => {
            navigate(); // required for update to be registered
        });
    }
    const onTaskCancel = (taskId) => {
        taskCancelAction({ params: { eventId: eventId, taskId: taskId } }).then(() => {
            navigate(); // required for update to be registered
        });
    }

    const onTokenStart = (tokenId) => {
        tokenFindAction({ params: { eventId: eventId, tokenId: tokenId } }).then(() => {
            navigate(); // required for update to be registered
        });
    }
    const onTokenCancel = (tokenId) => {
        tokenCancelAction({ params: { eventId: eventId, tokenId: tokenId } }).then(() => {
            navigate(); // required for update to be registered
        });
    }
    const onTokenManual = (tokenText) => {
        tokenCollectManualAction({ params: { eventId: eventId, tokenText: tokenText } }).then(() => {
            navigate(); // required for update to be registered
        });
    }

    const qrButton = (canUseQr) ? <Button key="qrButton" variant="outline-primary" onClick={onQrScanButton}><i className="bi bi-qr-code"></i> Scan</Button> : <></>;
    const shareButton = <Button key="shareButton" variant="outline-primary" onClick={onShareButton}><i className="bi bi-share"></i> Share</Button>;
    const exitButton = <Button key="exitButton" variant="outline-danger" onClick={onExitButton}><i className="bi bi-x-lg"></i></Button>;
    const updateButton = <Button key="updateButton" variant="outline-warning" onClick={onUpdateButton}><i className="bi bi-arrow-clockwise"></i></Button>;
    const availablePoints = participantEvent.getAvailablePoints();
    const collectedPoints = participantEvent.getSumCollectedPoints();
    const availablePointsBadge = <Badge key="availablePointsBadge">{availablePoints} Points available</Badge>;
    const collectedPointsBadge = <Badge key="collectedPointsBadge" bg="secondary">{collectedPoints} Points collected</Badge>;

    const getActionButtons = () => {
        let buttons = [];

        buttons.push(exitButton);
        buttons.push(updateButton);

        if (isAvailable) {
            buttons.push(qrButton);
            buttons.push(shareButton);
        }

        return (
            <Stack key="actions" direction="horizontal" gap={2}>
                {buttons.flat()}
            </Stack>
        );
    }

    return (
        <FullPageContainer alignCenter={false}>
            <ConfirmationModal title="Delete the photo" text="Are you sure you want to delete the selected image? I can not be restored." visible={showDeleteImageConfirmationModal} onClose={onTaskDeletePictureConfirmed} />
            <QRReaderModal visible={showQrReaderModal} title="Scan an Event QR Code" onClose={onQrCodeRead} onError={onQrCodeError} />
            <QRExportModal visible={showQRrShareModal} title="Share this Event" onClose={onQrShareClosed} link={participantEvent.getEvent().getPreviewLink()} />
            <SelectImageModal visible={showSelectImageModal} imageReference={imageSelectionImage} title="Image selection" onClose={onSelectImageModalClosed} />

            <Card className="w-100">
                <div className="image-container text-white rounded-top">
                    <Card.Img src={participantEvent.getEvent().getImage()} alt="Event Image" />
                    <div className="p-4 overlay">
                        <Card.Title as="h3">{participantEvent.getEvent().getTitle()}</Card.Title>
                        <Stack key="details" direction="horizontal" className="pb-3" gap={2}>
                            {availablePointsBadge} {availablePoints !== collectedPoints ? collectedPointsBadge : <></>}
                        </Stack>
                        {getActionButtons()}
                    </div>
                </div>
                <div className="image-container-follower">
                    <Card.Body>
                        <Tabs defaultActiveKey="tasks" activeKey={tabSelection} onSelect={(tab) => { setTabSelection(tab) }}>
                            <Tab eventKey="tasks" title="Tasks" className="p-3 border border-top-0 rounded-bottom">
                                <TaskContainer isAvailable={isAvailable} lastChangeId={lastChangeId} tasks={participantEvent.getEvent().getTasks()} registeredTasks={participantEvent.getTasks()} onTaskStart={onTaskStart} onTaskCancel={onTaskCancel} onTaskSelectPicture={onTaskSelectPicture} onTaskDeletePicture={onTaskDeletePicture} />
                            </Tab>
                            <Tab eventKey="tokens" title="Tokens" className="p-3 border border-top-0 rounded-bottom">
                                <TokenContainer isAvailable={isAvailable} lastChangeId={lastChangeId} tokens={participantEvent.getEvent().getTokens()} foundTokens={participantEvent.getTokens()} onTokenStart={onTokenStart} onTokenCancel={onTokenCancel} onTokenManual={onTokenManual} />
                            </Tab>
                            <Tab eventKey="rewards" title="Rewards" className="p-3 border border-top-0 rounded-bottom">
                                <RewardContainer lastChangeId={lastChangeId} rewards={participantEvent.getEvent().getRewards()} collectedRewards={participantEvent.getRewards()} availablePoints={availablePoints} />
                            </Tab>
                        </Tabs>
                    </Card.Body>
                    <Card.Footer className="text-body-secondary">
                        {availabilityText()}
                    </Card.Footer>
                </div>
            </Card>
        </FullPageContainer>
    );
}
