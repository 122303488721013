import { React, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "react-bootstrap";

EventApiConfirmationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    provideEventIdInput: PropTypes.bool
}
export function EventApiConfirmationModal({ visible, onClose, provideEventIdInput = false }) {
    const [secret, setSecret] = useState("");
    const [eventId, setEventId] = useState("");

    const onCancel = () => {
        onClose("", "")
    }
    const onConfirmation = () => {
        onClose(secret, eventId)
    }
    const onEnterKeyDown = (e) => {
        if (e.key.toLowerCase() === "enter") {
            onConfirmation();
        }
    }

    return (
        <Modal show={visible} fullscreen="xl-down" onHide={() => onCancel()} onSubmit={(e) => { e.preventDefault(); }}>
            <Modal.Header closeButton>
                <Modal.Title>Editor Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className={`mb-3 ${!provideEventIdInput ? "d-none" : ""}`} controlId="eventId">
                        <Form.Label>Event Id</Form.Label>
                        <Form.Control type="text" placeholder="Event ID" autoFocus={provideEventIdInput} value={eventId} onChange={(e) => setEventId(e.target.value)} onKeyDown={(e) => onEnterKeyDown(e)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="secret">
                        <Form.Label>Secret</Form.Label>
                        <Form.Control type="password" placeholder="Event secret" autoFocus={!provideEventIdInput} value={secret} onChange={(e) => setSecret(e.target.value)} onKeyDown={(e) => onEnterKeyDown(e)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onCancel()}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onConfirmation()}>
                    <i className="bi bi-check-lg"></i> Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  