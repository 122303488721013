import { React, useState } from "react";
import PropTypes from "prop-types";
import { Button, Figure, Form, Modal } from "react-bootstrap";
import { convertDateForOutput, convertDateFromInput, imageSelectionProcesser } from "../../logic/helper";
import { useNavigate } from "react-router-dom";
import { registerNotification } from "../../logic/database";
import { EventDefinition } from "../../logic/event/eventDefinition";

EventDetailModal.propTypes = {
    eventState: PropTypes.objectOf(EventDefinition).isRequired,   
    onClose: PropTypes.func.isRequired,
}
export function EventDetailModal({ eventState, onClose }) {
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [joincode, setJoinCode] = useState("");
    const [image, setImage] = useState("");
    const [version, setVersion] = useState(1);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");

    const onOpen = () => {
        setTitle(eventState.getTitle());
        setDescription(eventState.getDescription());
        setJoinCode(eventState.getJoinCode());
        setImage(eventState.getImage());
        setStart(eventState.getStart());
        setEnd(eventState.getEnd());
        setVersion(eventState.getVersion());
    }

    const onSave = () => {
        eventState.setTitle(title);
        eventState.setDescription(description);
        eventState.setJoinCode(joincode);
        eventState.setImage(image);
        eventState.setStart(start);
        eventState.setEnd(end);
        eventState.setVersion(version);
        onClose(true, eventState);
    }

    const onEnterKeyDown = (e) => {
        if (e.key.toLowerCase() === "enter") {
            onSave();
        }
    }

    const onImageSelection = (file) => {
        imageSelectionProcesser(file)
            .then((result) => {
                setImage(result);

                registerNotification({
                    id: `image-selected`,
                    title: "Image loaded",
                    text: "The image looks good!",
                    delay: 5000
                }).then(() => {
                    navigate();
                });
            }).catch(() => {
                registerNotification({
                    id: `image-selection-failed`,
                    variant: "danger",
                    title: "Image failed to load",
                    text: "Oops, we have trouble with the selected image.",
                    delay: 10000
                }).then(() => {
                    navigate();
                });
            });
    }

    return (
        <Modal show={!!(eventState)} backdrop="static" size="lg" fullscreen="xl-down" onHide={() => onClose(false)} onShow={() => onOpen()}>
            <Modal.Header closeButton>
                <Modal.Title>Event Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" placeholder="Event title" autoFocus value={title} onChange={(e) => setTitle(e.target.value)} onKeyDown={(e) => onEnterKeyDown(e)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Event description" value={description} onChange={(e) => setDescription(e.target.value)} onKeyDown={(e) => onEnterKeyDown(e)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="joinCode">
                        <Form.Label>Join Code</Form.Label>
                        <Form.Control type="text" placeholder="Event Join Code" maxLength={10} value={joincode} onChange={(e) => setJoinCode(e.target.value)} onKeyDown={(e) => onEnterKeyDown(e)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="version">
                        <Form.Label>Version</Form.Label>
                        <Form.Control type="number" placeholder="Event Version" value={version} min={1} onChange={(e) => setVersion(Number.parseInt(e.target.value))} onKeyDown={(e) => onEnterKeyDown(e)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="start">
                        <Form.Label>Start</Form.Label>
                        <Form.Control type="datetime-local" placeholder="Event start" value={convertDateForOutput(start)} onChange={(e) => setStart(convertDateFromInput(e.target.value))} onKeyDown={(e) => onEnterKeyDown(e)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="end">
                        <Form.Label>End</Form.Label>
                        <Form.Control type="datetime-local" placeholder="Event end" value={convertDateForOutput(end)} onChange={(e) => setEnd(convertDateFromInput(e.target.value))} onKeyDown={(e) => onEnterKeyDown(e)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="image" >
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" placeholder="Select an image" accept="image/png, image/jpeg" onChange={(e) => onImageSelection(e.target.files[0])} />
                    </Form.Group>
                    <Form.Group className={image ? "" : "d-none"}>
                        <Figure>
                            <Figure.Image
                                width={600}
                                height={400}
                                alt="Preview Image"
                                src={image}
                                rounded
                            />
                            <Figure.Caption>
                                Downsized preview of the selected image.
                            </Figure.Caption>
                        </Figure>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onClose(false)}>
                    <i className="bi bi-x-lg"></i> Close
                </Button>
                <Button variant="primary" onClick={() => onSave()}>
                    <i className="bi bi-save"></i> Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}  