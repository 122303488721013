import { React } from "react";
import PropTypes from "prop-types";
import { Stack } from "react-bootstrap";
import { EventTask } from "../../logic/event/eventTask"
import { TaskCard } from "./TaskCard";
import { TaskActionBar } from "./TaskActionBar";
import { useState } from "react";
import { TaskDetailModal } from "./TaskDetailModal";
import { ConfirmationModal } from "../ConfirmationModal";

TaskList.propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.objectOf(EventTask)).isRequired,
    onTaskDeleteEvent: PropTypes.func.isRequired,
    onTaskChangeEvent: PropTypes.func.isRequired,
}
export function TaskList({ tasks, onTaskDeleteEvent, onTaskChangeEvent }) {
    const [taskState, setTaskState] = useState(undefined);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const createClick = () => {
        setTaskState(new EventTask()); // Open Modal with a fresh task
    }
    const editClick = (task) => {
        setTaskState(task.getClone()); // Open Modal with an existing task
    }

    const modalCloseEvent = (wasSaved, iTaskState) => {
        // If the save button was clicked propagate the changes up
        if (wasSaved) {
            onTaskChangeEvent(iTaskState);
        }
        setTaskState(undefined); // Reset the task state to hide the modal
    }

    const deleteAllTasksEvent = () => {
        setDeleteModalVisible(true); // Ask user for confirmation before delete
    }
    const deleteAllTasksConfirmation = (wasConfirmed) => {
        if (wasConfirmed){
            for (let i = 0; i < tasks.length; i++) {
                const task = tasks[i];
                if (!(task instanceof EventTask)) {
                    continue;
                }
                onTaskDeleteEvent(task.getId());
            }
        }
        setDeleteModalVisible(false);
    }

    const stackItems = tasks.map((task) => {
        if (!(task instanceof EventTask)) {
            return <></>;
        }
        return <TaskCard task={task} key={task.getId()} onDeleteClick={onTaskDeleteEvent} onEditClick={editClick} />
    });
    return (
        <>
            <TaskDetailModal taskState={taskState} onClose={modalCloseEvent} />
            <ConfirmationModal visible={deleteModalVisible} title="Delete all tasks?" text="Do you want to delete all tasks?" onClose={deleteAllTasksConfirmation} />
            <TaskActionBar totalTasks={tasks.length} onCreateClick={createClick} onDeleteAllClick={deleteAllTasksEvent} />
            <Stack gap={3}>
                {stackItems}
            </Stack>
        </>
    )
}  