import { React } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Stack } from "react-bootstrap";
import { ConfirmationModal } from "../ConfirmationModal";
import { EventReward } from "../../logic/event/eventReward";
import { RewardCard } from "./RewardCard";
import { RewardDetailModal } from "./RewardDetailModal";
import { RewardActionBar } from "./RewardActionBar";

RewardList.propTypes = {
    rewards: PropTypes.arrayOf(PropTypes.objectOf(EventReward)).isRequired,
    onRewardDeleteEvent: PropTypes.func.isRequired,
    onRewardChangeEvent: PropTypes.func.isRequired,
}
export function RewardList({ rewards, onRewardDeleteEvent, onRewardChangeEvent }) {
    const [rewardState, setRewardState] = useState(undefined);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const createClick = () => {
        setRewardState(new EventReward()); // Open Modal with a fresh reward
    }
    const editClick = (reward) => {
        setRewardState(reward.getClone()); // Open Modal with an existing reward
    }

    const modalCloseEvent = (wasSaved, iRewardState) => {
        // If the save button was clicked propagate the changes up
        if (wasSaved) {
            onRewardChangeEvent(iRewardState);
        }
        setRewardState(undefined); // Reset the reward state to hide the modal
    }

    const deleteAllRewardsEvent = () => {
        setDeleteModalVisible(true); // Ask user for confirmation before delete
    }
    const deleteAllRewardsConfirmation = (wasConfirmed) => {
        if (wasConfirmed){
            for (let i = 0; i < rewards.length; i++) {
                const reward = rewards[i];
                if (!(reward instanceof EventReward)) {
                    continue;
                }
                onRewardDeleteEvent(reward.getId());
            }
        }
        setDeleteModalVisible(false);
    }

    const stackItems = rewards.map((reward) => {
        if (!(reward instanceof EventReward)) {
            return <></>;
        }
        return <RewardCard reward={reward} key={reward.getId()} onDeleteClick={onRewardDeleteEvent} onEditClick={editClick} />
    });
    return (
        <>
            <RewardDetailModal rewardState={rewardState} onClose={modalCloseEvent} />
            <ConfirmationModal visible={deleteModalVisible} title="Delete all rewards?" text="Do you want to delete all rewards?" onClose={deleteAllRewardsConfirmation} />
            <RewardActionBar totalRewards={rewards.length} onCreateClick={createClick} onDeleteAllClick={deleteAllRewardsEvent} />
            <Stack gap={3}>
                {stackItems}
            </Stack>
        </>
    )
}  