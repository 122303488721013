import { React } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Spinner } from "react-bootstrap";

ConfirmationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}
export function ConfirmationModal({ visible, loading = false, title, text, onClose }) {
    return (
        <Modal show={visible} onHide={() => { onClose(false) }} backdrop={loading ? "static" : true} keyboard={!loading}>
            <Modal.Header closeButton={!loading}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>
                {loading ?
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    :
                    <>
                        <Button variant="primary" onClick={() => { onClose(true) }}>
                            <i className="bi bi-check-lg"></i> Yes
                        </Button>
                        <Button variant="secondary" onClick={() => { onClose(false) }}>
                            <i className="bi bi-x-lg"></i> No
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}