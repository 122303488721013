import { React } from "react";
import PropTypes from "prop-types";
import { Stack } from "react-bootstrap";
import { useState } from "react";
import { EventToken } from "../../logic/event/eventToken";
import { TokenCard } from "./TokenCard";
import { TokenDetailModal } from "./TokenDetailModal";
import { TokenActionBar } from "./TokenActionBar";
import { ConfirmationModal } from "../ConfirmationModal";

TokenList.propTypes = {
    tokens: PropTypes.arrayOf(PropTypes.objectOf(EventToken)).isRequired,
    onTokenDeleteEvent: PropTypes.func.isRequired,
    onTokenChangeEvent: PropTypes.func.isRequired,
}
export function TokenList({ tokens, onTokenDeleteEvent, onTokenChangeEvent }) {
    const [tokenState, setTokenState] = useState(undefined);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const createClick = () => {
        setTokenState(new EventToken()); // Open Modal with a fresh token
    }
    const editClick = (token) => {
        setTokenState(token.getClone()); // Open Modal with an existing token
    }

    const modalCloseEvent = (wasSaved, iTokenState) => {
        // If the save button was clicked propagate the changes up
        if (wasSaved) {
            onTokenChangeEvent(iTokenState);
        }
        setTokenState(undefined); // Reset the token state to hide the modal
    }

    const deleteAllTokensEvent = () => {
        setDeleteModalVisible(true); // Ask user for confirmation before delete
    }
    const deleteAllTokensConfirmation = (wasConfirmed) => {
        if (wasConfirmed){
            for (let i = 0; i < tokens.length; i++) {
                const token = tokens[i];
                if (!(token instanceof EventToken)) {
                    continue;
                }
                onTokenDeleteEvent(token.getId());
            }
        }
        setDeleteModalVisible(false);
    }

    const stackItems = tokens.map((token) => {
        if (!(token instanceof EventToken)) {
            return <></>;
        }
        return <TokenCard token={token} key={token.getId()} onDeleteClick={onTokenDeleteEvent} onEditClick={editClick} />
    });
    return (
        <>
            <TokenDetailModal tokenState={tokenState} onClose={modalCloseEvent} />
            <ConfirmationModal visible={deleteModalVisible} title="Delete all tokens?" text="Do you want to delete all tokens?" onClose={deleteAllTokensConfirmation} />
            <TokenActionBar totalTokens={tokens.length} onCreateClick={createClick} onDeleteAllClick={deleteAllTokensEvent} />
            <Stack gap={3}>
                {stackItems}
            </Stack>
        </>
    )
}
