import { React } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { CustomQRReader } from "./CustomQRReader";

QRReaderModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
}
export function QRReaderModal({ visible, title, onClose, onError }) {
    return (
        <Modal show={visible} onHide={() => { onClose(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomQRReader onUpdate={(result) => { onClose(result[0]?.rawValue) }} onError={(error) => { onError(error) }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { onClose(false) }}>
                    <i className="bi bi-x-lg"></i> Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}