import { React, useCallback } from "react";
import { Stack, Tab, Tabs } from "react-bootstrap";
import { FullPageContainer } from "../components/FullPageContainer";
import { useEffect, useState } from "react";
import { TaskList } from "../components/editor/TaskList";
import { TokenList } from "../components/editor/TokenList";
import { RewardList } from "../components/editor/RewardList";
import { EventOverview } from "../components/editor/EventOverview";
import { EventDefinition } from "../logic/event/eventDefinition";
import { useNavigate } from "react-router-dom";
import { registerNotification } from "../logic/database";
import { confirmAccessCode, downloadEventById, updateEvent, downloadEventImages as downloadEventImagesWeb, deleteEvent as deleteEventWeb } from "../logic/web";
import { AccessCodeModal } from "../components/editor/AccessCodeModal";
import { getClipboardText, setClipboardText } from "../logic/helper";

let ed = new EventDefinition();
const ACCESS_CODE_IDENTIFIER = "accessCode";

export function Editor() {
  const navigate = useNavigate();
  //const [eventState, setEventState] = useState(ed);
  const [eventTasks, setEventTasks] = useState(ed.getTasks());
  const [eventTokens, setEventTokens] = useState(ed.getTokens());
  const [eventRewards, setEventRewards] = useState(ed.getRewards());
  const [editorAccessCode, setEditorAccessCode] = useState("");

  const validateAccessCode = useCallback(async (accessCode) => {
    try {
      const result = await confirmAccessCode(accessCode);
      if (result?.ok) {
        localStorage.setItem(ACCESS_CODE_IDENTIFIER, accessCode);
        setEditorAccessCode(accessCode);
      }
    } catch (error) {
      registerNotification({
        id: "access-code-rejected",
        variant: "danger",
        title: "Invalid access code",
        text: "Failed the provided access code was not accepted.",
        delay: 10000,
      }).then(() => {
        navigate();
      });
    }
  }, [navigate]);

  useEffect(() => {
    const localAccessCode = localStorage.getItem(ACCESS_CODE_IDENTIFIER) || "";
    if (localAccessCode) {
      validateAccessCode(localAccessCode);
    }
  }, [validateAccessCode]);

  const updateInternalStates = () => {
    //setEventState(ed);
    setEventTasks(ed.getTasks());
    setEventTokens(ed.getTokens());
    setEventRewards(ed.getRewards());
  }

  const fullReset = () => {
    ed = new EventDefinition();
    updateInternalStates();
  }
  const eventChange = (newEventData) => {
    ed.setTitle(newEventData.getTitle());
    ed.setDescription(newEventData.getDescription());
    ed.setJoinCode(newEventData.getJoinCode());
    ed.setImage(newEventData.getImage());
    ed.setStart(newEventData.getStart());
    ed.setEnd(newEventData.getEnd());
    ed.setVersion(newEventData.getVersion());
  }

  const deleteTaskEvent = (taskId) => {
    ed.removeTaskById(taskId);
    setEventTasks(ed.getTasks());
  }
  const editTaskEvent = (newTaskData) => {
    if (ed.knownTask(newTaskData)) {
      ed.updateTask(newTaskData);
    } else {
      ed.addTask(newTaskData);
    }
    setEventTasks(ed.getTasks());
  }

  const deleteTokenEvent = (tokenId) => {
    ed.removeTokenById(tokenId);
    setEventTokens(ed.getTokens());
  }
  const editTokenEvent = (newTokenData) => {
    if (ed.knownToken(newTokenData)) {
      ed.updateToken(newTokenData);
    } else {
      ed.addToken(newTokenData);
    }
    setEventTokens(ed.getTokens());
  }

  const deleteRewardEvent = (rewardId) => {
    ed.removeRewardById(rewardId);
    setEventRewards(ed.getRewards());
  }
  const editRewardEvent = (newRewardData) => {
    if (ed.knownReward(newRewardData)) {
      ed.updateReward(newRewardData);
    } else {
      ed.addReward(newRewardData);
    }
    setEventRewards(ed.getRewards());
  }

  const exportEventId = () => {
    setClipboardText(ed.getId()).then(() => {
      registerNotification({
        id: "clipboard-export-id-completed",
        title: "Copied id to clipboard",
        text: "The event id was copied to the clipboard!",
        delay: 5000,
      }).then(() => {
        navigate();
      });
    }).catch(() => {
      registerNotification({
        id: "clipboard-export-id-failed",
        variant: "danger",
        title: "Failed to copy id",
        text: "Unable to copy the event id!",
        delay: 10000,
      }).then(() => {
        navigate();
      });
    })
  }
  const exportEvent = () => {
    setClipboardText(ed.getExport()).then(() => {
      registerNotification({
        id: "clipboard-export-completed",
        title: "Export completed",
        text: "The exported data is now available in the clipboard!",
        delay: 5000,
      }).then(() => {
        navigate();
      });
    }).catch(() => {
      registerNotification({
        id: "clipboard-export-failed",
        variant: "danger",
        title: "Export failed",
        text: "The export of the event data to the clipboard failed!",
        delay: 10000,
      }).then(() => {
        navigate();
      });
    })
  }
  const importEvent = () => {
    getClipboardText().then((readText) => {
      try {
        ed = new EventDefinition();
        ed.applyExport(JSON.parse(readText));
        updateInternalStates();

        registerNotification({
          id: "clipboard-imported",
          title: "Import completed",
          text: "The clipboard data was imported!",
          delay: 5000,
        }).then(() => {
          navigate();
        });
      } catch (error) {
        registerNotification({
          id: "clipboard-import-failed",
          variant: "danger",
          title: "Import failed",
          text: "The clipboard contained faulty event data!",
          delay: 10000,
        }).then(() => {
          navigate();
        });
      }
    }).catch(() => {
      registerNotification({
        id: "clipboard-import-failed",
        variant: "danger",
        title: "Import failed",
        text: "Failed to read the event payload from the clipboard!",
        delay: 10000,
      }).then(() => {
        navigate();
      });
    });
  }

  const uploadEvent = (secret) => {
    ed.updateVersion();

    updateEvent(ed, secret, editorAccessCode).then(() => {
      registerNotification({
        id: "upload-completed",
        title: "Upload completed",
        text: "The uploaded event was updated!",
        delay: 5000,
      }).then(() => {
        navigate();
      });
    }).catch(() => {
      registerNotification({
        id: "upload-failed",
        variant: "danger",
        title: "Upload failed",
        text: "Failed to upload the event change!",
        delay: 10000,
      }).then(() => {
        navigate();
      });
    })
  }
  const downloadEvent = (eventId, secret) => {
    downloadEventById(eventId, secret, editorAccessCode).then((result) => {
      ed = new EventDefinition();
      ed.applyExport(result);
      updateInternalStates();

      registerNotification({
        id: "download-completed",
        title: "Download completed",
        text: "The event was downloaded!",
        delay: 5000,
      }).then(() => {
        navigate();
      });
    }).catch(() => {
      registerNotification({
        id: "download-failed",
        variant: "danger",
        title: "Download failed",
        text: "Failed to download the event data!",
        delay: 10000,
      }).then(() => {
        navigate();
      });
    })
  }
  const downloadEventImages = (secret) => {
    downloadEventImagesWeb(ed.getId(), secret, editorAccessCode).then((result) => {
      const url = window.URL.createObjectURL(result);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `images.zip`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      registerNotification({
        id: "download-images-completed",
        title: "Download event images completed",
        text: "The event images were downloaded!",
        delay: 5000,
      }).then(() => {
        navigate();
      });
    }).catch(() => {
      registerNotification({
        id: "download-images-failed",
        variant: "danger",
        title: "Download event images failed",
        text: "Failed to download the event images!",
        delay: 10000,
      }).then(() => {
        navigate();
      });
    })
  }
  const deleteEvent = (secret) => {
    deleteEventWeb(ed.getId(), secret, editorAccessCode)
      .then(() => {
        registerNotification({
          id: "delete-completed",
          title: "The event was deleted",
          text: "The uploaded event and related data was deleted!",
          delay: 5000,
        }).then(() => {
          navigate();
        });
      }).catch(() => {
        registerNotification({
          id: "delete-failed",
          variant: "danger",
          title: "Failed to delete",
          text: "An error was encountered while deleting the event related data!",
          delay: 10000,
        }).then(() => {
          navigate();
        });
      })
  }

  const closeAccessCodeModal = async (accessCode) => {
    if (accessCode) {
      await validateAccessCode(accessCode);
    } else {
      navigate("/");
    }
  }

  return (
    <FullPageContainer>
      {editorAccessCode.length <= 0 ?
        <AccessCodeModal visible={editorAccessCode.length <= 0} onClose={closeAccessCodeModal} />
        :
        <Stack>
          <EventOverview
            eventState={ed}
            onEventChange={eventChange}
            onEventReset={fullReset}
            onEventExport={exportEvent}
            onEventImport={importEvent}
            onEventUpload={uploadEvent}
            onEventDownload={downloadEvent}
            onEventImageDownload={downloadEventImages}
            onCloudDeleteEvent={deleteEvent}
            onEventExportId={exportEventId}
          />

          <Tabs defaultActiveKey="tasks" className="pt-3">
            <Tab eventKey="tasks" title="Tasks" key="tasks" className="p-3 border border-top-0 rounded-bottom">
              <TaskList
                tasks={eventTasks}
                onTaskDeleteEvent={deleteTaskEvent}
                onTaskChangeEvent={editTaskEvent}
              />
            </Tab>
            <Tab eventKey="tokens" title="Tokens" key="tokens" className="p-3 border border-top-0 rounded-bottom">
              <TokenList
                tokens={eventTokens}
                onTokenDeleteEvent={deleteTokenEvent}
                onTokenChangeEvent={editTokenEvent}
              />
            </Tab>
            <Tab eventKey="rewards" title="Rewards" key="rewards" className="p-3 border border-top-0 rounded-bottom">
              <RewardList
                rewards={eventRewards}
                onRewardDeleteEvent={deleteRewardEvent}
                onRewardChangeEvent={editRewardEvent}
              />
            </Tab>
          </Tabs>
        </Stack>
      }
    </FullPageContainer>
  );
}