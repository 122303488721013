import { getNow } from "./helper";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export function getEventVersionById(eventId) {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/event/${eventId}/version?t=${getNow()}`, {
            headers: {
                "Accept": "application/json",
            }
        }
        ).then(async (response) => {
            if (response.ok) {
                resolve(await response.json());
            } else {
                reject(new Error("error response"));
            }
        }).catch((e) => {
            reject(e);
        });
    })
}

export function getEventById(eventId, joinCode = "") {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/event/${eventId}?t=${getNow()}`,
            {
                headers: {
                    "joincode": joinCode,
                    "Accept": "application/json",
                }
            }).then(async (response) => {
                if (response.ok) {
                    resolve(await response.json());
                } else {
                    reject(new Error("error response"));
                }
            }).catch((e) => {
                reject(e);
            });
    })
}

export function uploadImage(internalImageUpload, image) {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/event/${internalImageUpload.getEventId()}/image`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ image: image }),
        }).then(async (response) => {
            if (response.ok) {
                resolve(internalImageUpload);
            } else {
                reject(internalImageUpload);
            }
        }).catch((e) => {
            reject(e);
        });
    })
}

export function confirmAccessCode(accessCode) {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/editor/access`, {
            headers: {
                "accessCode": accessCode,
                "Accept": "application/json",
                "Content-Type": "application/json",
            }
        }).then(async (response) => {
            if (response.ok) {
                resolve(await response.json());
            } else {
                reject(new Error("error response"));
            }
        }).catch((e) => {
            reject(e);
        });
    });
}

export function downloadEventById(eventId, secret, accessCode) {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/editor/action/${eventId}?t=${getNow()}`,
            {
                headers: {
                    "secret": secret,
                    "accessCode": accessCode,
                    "Accept": "application/json",
                }
            }).then(async (response) => {
                if (response.ok) {
                    resolve(await response.json());
                } else {
                    reject(new Error("error response"));
                }
            }).catch((e) => {
                reject(e);
            });
    })
}

export function updateEvent(event, secret, accessCode) {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/editor/action/${event.getId()}`, {
            method: "POST",
            headers: {
                "secret": secret,
                "accessCode": accessCode,
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: event.getExport()
        }).then(async (response) => {
            if (response.ok) {
                resolve(await response.json());
            } else {
                reject(new Error("error response"));
            }
        }).catch((e) => {
            reject(e);
        });
    })
}

export function deleteEvent(eventId, secret, accessCode) {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/editor/action/${eventId}`, {
            method: "DELETE",
            headers: {
                "secret": secret,
                "accessCode": accessCode,
                "Accept": "application/json",
            }
        }).then(async (response) => {
            if (response.ok) {
                resolve(await response.json());
            } else {
                reject(new Error("error response"));
            }
        }).catch((e) => {
            reject(e);
        });
    })
}


export function downloadEventImages(eventId, secret, accessCode) {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/editor/action/${eventId}/images`, {
            headers: {
                "secret": secret,
                "accessCode": accessCode,
                "Accept": "application/zip",
                "Content-Type": "application/json",
            }
        }).then(async (response) => {
            if (response.ok) {
                resolve(await response.blob());
            } else {
                reject(new Error("error response"));
            }
        }).catch((e) => {
            reject(e);
        });
    });
}



