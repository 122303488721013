import { isString } from "../validation";

export class InternalImageUpload {
    
    constructor() {
        this.eventId = "";
        this.taskId = "";
    }

    getEventId() {
        return this.eventId;
    }
    getTaskId() {
        return this.taskId;
    }
    
    applyExport(params = { eventId: "", taskId: "" }) {
        this.setEventId(params.eventId);
        this.setTaskId(params.taskId);

        return this;
    }

    setEventId(eventId = "") {
        if (!isString(eventId)) {
            throw new InternalImageUploadError("Invalid parameter type: eventId");
        }
        this.eventId = eventId;
        return this;
    }
    setTaskId(taskId = "") {
        if (!isString(taskId)) {
            throw new InternalImageUploadError("Invalid parameter type: taskId");
        }
        this.taskId = taskId;
        return this;
    }
}

export class InternalImageUploadError extends Error {
    constructor(message) {
        super(message);
        this.name = "InternalImageUploadError";
    }
}