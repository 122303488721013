import { React } from "react";
import PropTypes from "prop-types";
import { IDENTIFIER as ImageUploadIdentifier, IDENTIFIER_MULTI as ImageMultiUploadIdentifier, ImageUploadConfirmation } from "./ImageUploadConfirmation";

DataNotificationWrapper.propTypes = {
    data: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
}
export function DataNotificationWrapper({ data, onClose }) {
    if (data[0] === ImageUploadIdentifier || data[0] === ImageMultiUploadIdentifier) {
        return (<ImageUploadConfirmation data={data} onClose={onClose} />)
    }
    return (<></>);
}