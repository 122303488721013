import { React } from "react";
import PropTypes from "prop-types";

FullPageContainer.propTypes = {
    children: PropTypes.any.isRequired,
    alignCenter: PropTypes.bool,
}
export function FullPageContainer({ children, alignCenter = true }) {
    const alignment = (alignCenter) ? "align-items-center justify-content-center" : "align-items-start justify-content-start";
    return (
        <div className={`h-100 d-flex ${alignment}`}>
            {children}
        </div>
    )
}  