import { React } from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import { CustomToastContainer } from "../components/CustomToastContainer";
import { getNotifications } from "../logic/database";
import { useEffect, useState } from "react";
import { CustomToast } from "../components/CustomToast";
import { DataNotificationWrapper } from "../components/DataNotificationWrapper";

export async function Loader({ params }) {
  let gerror;
  let notifications;
  try {
    notifications = await getNotifications();
  } catch (error) {
    gerror = error;
  }
  return { notifications, errorContext: gerror, params };
}

export function Root() {
  const { notifications } = useLoaderData();
  const [activeNotifications, setActiveNotifications] = useState([]);
  const [dataNotifications, setDataNotifications] = useState([]);

  useEffect(() => {
    setActiveNotifications((currentActive) => {
      const knownIds = currentActive.map((item) => {
        return item.getId();
      });
      const newNotifications = notifications.filter((item) => {
        return !knownIds.includes(item.getId()) && item.getDisplay();
      });
      return [...currentActive, ...newNotifications];
    });
    setDataNotifications((currentData) => {
      const newDataNotifications = notifications.filter((item) => {
        return !item.getDisplay();
      });
      return [...currentData, ...newDataNotifications];
    });
  }, [notifications]);

  const dynamicNotifications = activeNotifications.map((notification) => {
    return (
      <CustomToast
        key={notification.getId()}
        title={notification.getTitle()}
        text={notification.getText()}
        delay={notification.getDelay()}
        variant={notification.getVariant()}
        position={notification.getPosition()}
        visible
        onClose={() => {
          const filteredNotifications = activeNotifications.filter((iNotification) => {
            return iNotification.getId() !== notification.getId();
          });
          setActiveNotifications([...filteredNotifications]);
        }}
      />
    );
  });
  const dataNotificationsHandler = dataNotifications.map((notification) => {
    return (
      <DataNotificationWrapper
        key={notification.getId()}
        data={notification.getData()}
        onClose={() => {
          const filteredNotifications = dataNotifications.filter((iNotification) => {
            return iNotification.getId() !== notification.getId();
          });
          setDataNotifications([...filteredNotifications]);
        }}
      />
    )
  });

  return (
    <>
      <CustomToastContainer>
        {dynamicNotifications}
      </CustomToastContainer>
      {dataNotificationsHandler}
      <Outlet />
    </>
  );
}