import { React } from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-bootstrap";

CustomToastContainer.propTypes = {
    children: PropTypes.any.isRequired,
    position: PropTypes.string,
}
export function CustomToastContainer({ children, position = "top-end" }) {
    return (
        <ToastContainer position={position} className="p-3" style={{ zIndex: 5000 }}>
            {children}
        </ToastContainer>
    )
}