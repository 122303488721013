import { React } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { getFromArray } from "../logic/helper";
import { TaskCard } from "./TaskCard";
import { EventTask } from "../logic/event/eventTask";
import { ParticipantEventTask } from "../logic/participant/participantEventTask";

TaskContainer.propTypes = {
    isAvailable: PropTypes.bool.isRequired,
    lastChangeId: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.objectOf(EventTask)).isRequired,
    registeredTasks: PropTypes.arrayOf(PropTypes.objectOf(ParticipantEventTask)).isRequired,
    onTaskStart: PropTypes.func.isRequired,
    onTaskCancel: PropTypes.func.isRequired,
    onTaskTakePicture: PropTypes.func.isRequired,
    onTaskSelectPicture: PropTypes.func.isRequired,
    onTaskDeletePicture: PropTypes.func.isRequired,
}
export function TaskContainer({ isAvailable, lastChangeId, tasks, registeredTasks, onTaskStart, onTaskCancel, onTaskTakePicture, onTaskSelectPicture, onTaskDeletePicture }) {
    if (!(tasks instanceof Array) || !(registeredTasks instanceof Array)) {
        return <></>;
    }
    const taskCards = tasks.filter((task) => {
        if (!(task instanceof EventTask)) {
            return false;
        }
        if (!task.isDirectlyAvailable()) {
            const participantTask = getFromArray(registeredTasks, task);
            if (!participantTask) {
                return false;
            }
        }
        return true;
    }).sort((taskA, taskB) => {
        if (taskA.getId() === lastChangeId) {
            return -1;
        } else if (taskB.getId() === lastChangeId) {
            return 1;
        }

        const participantTaskA = getFromArray(registeredTasks, taskA);
        const participantTaskB = getFromArray(registeredTasks, taskB);

        // Order by not yet started tasks
        if (!participantTaskA && !participantTaskB) {
            return 0; // Equal and if neither is active yet there is nothing to compare
        }
        if (!participantTaskA) {
            return 1; // The second item is already active, the first is not. Therefore the not yet active itemA must be displayed first to hightlight it.
        }
        if (!participantTaskB) {
            return -1; // The first item is already active, the second ist not. Therefore the not yet active itemB must be displayed first to highlight it.
        }
        //At this point both are already active

        // Order by not yet completed tasks
        const statusA = participantTaskA.getStatus();
        const statusB = participantTaskB.getStatus();
        if (statusA.isActive() && !statusB.isActive()) {
            return -1; // The second item is no longer active but the first is. We want to promote tasks to be completed, therefore display itemA first.
        }
        if (!statusA.isActive() && statusB.isActive()) {
            return 1; // The first item is no longer active but the second is. We want to promote tasks to be completed, therefore display itemB first.
        }

        // At this point either both are active or both are completed.
        return statusB.getLatest() - statusA.getLatest();

    }).map((task) => {
        const participantTask = getFromArray(registeredTasks, task);
        return (
            <Row key={task.getId()}>
                <Col>
                    <TaskCard isAvailable={isAvailable} lastChangeId={lastChangeId} task={task} participantTask={participantTask} onTaskStart={onTaskStart} onTaskCancel={onTaskCancel} onTaskTakePicture={onTaskTakePicture} onTaskSelectPicture={onTaskSelectPicture} onTaskDeletePicture={onTaskDeletePicture} />
                </Col>
            </Row>
        );
    });
    return (
        <Container>
            <Row>
                <Col>Find and work on tasks to gain points and earn rewards.</Col>
            </Row>
            {taskCards}
        </Container>
    );
}