import { React, useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row, Image } from "react-bootstrap";
import { EventActionBar } from "./EventActionBar";
import { ConfirmationModal } from "../ConfirmationModal";
import { EventDetailModal } from "./EventDetailModal";
import { EventQRExportModal } from "./EventQRExportModal";
import { EventApiConfirmationModal } from "./EventApiConfirmationModal";
import { DEFAULT_TARGETS } from "../../logic/helper";
import { EventDefinition } from "../../logic/event/eventDefinition";

EventOverview.propTypes = {
    eventState: PropTypes.objectOf(EventDefinition).isRequired,   
    onEventReset: PropTypes.func.isRequired,
    onEventChange: PropTypes.func.isRequired,
    onEventExport: PropTypes.func.isRequired,
    onEventExportId: PropTypes.func.isRequired,
    onEventImport: PropTypes.func.isRequired,
    onEventUpload: PropTypes.func.isRequired,
    onEventDownload: PropTypes.func.isRequired,
    onEventImageDownload: PropTypes.func.isRequired,
    onCloudDeleteEvent: PropTypes.func.isRequired,
}
export function EventOverview({ eventState, onEventReset, onEventChange, onEventExport, onEventExportId, onEventImport, onEventUpload, onEventDownload, onEventImageDownload, onCloudDeleteEvent }) {
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [showEventQRExportModal, setShowEventQRExportModal] = useState(false);
    const [importModalVisible, setImportModalVisible] = useState(false);
    const [modalEventState, setModalEventState] = useState(undefined);

    const [uploadSecretVisible, setUploadSecretVisible] = useState(false);
    const [downloadSecretVisible, setDownloadSecretVisible] = useState(false);
    const [downloadImageSecretVisible, setDownloadImageSecretVisible] = useState(false);
    const [deleteSecretVisible, setDeleteSecretVisible] = useState(false);
    const [eventSecret, setEventSecret] = useState("");
    const [targetSelection, setTargetSelection] = useState(DEFAULT_TARGETS[0].key);

    const resetEvent = () => {
        setResetModalVisible(true); // Ask user for confirmation before full reset
    }
    const resetConfirmation = (wasConfirmed) => {
        if (wasConfirmed) {
            onEventReset();
        }
        setResetModalVisible(false);
    }

    const editEventClick = () => {
        setModalEventState(eventState.getClone()); // Open Modal with a fresh token
    }
    const modalCloseEvent = (wasSaved, iEventState) => {
        // If the save button was clicked propagate the changes up
        if (wasSaved) {
            onEventChange(iEventState);
        }
        setModalEventState(undefined); // Reset the token state to hide the modal
    }
    const exportEvent = () => {
        onEventExport();
    }
    const exportEventId = () => {
        onEventExportId();
    }
    const importEvent = () => {
        setImportModalVisible(true);
    }
    const importConfirmation = (wasConfirmed) => {
        if (wasConfirmed) {
            onEventImport();
        }
        setImportModalVisible(false);
    }
    const openQRExportModal = () => {
        setShowEventQRExportModal(true);
    }
    const closeQRExportModal = () => {
        setShowEventQRExportModal(false);
    }
    const uploadEvent = () => {
        if (eventSecret.length > 0) {
            onEventUpload(eventSecret);
        } else {
            setUploadSecretVisible(true);
        }
    }
    const closeUploadSecretModal = (secret) => {
        if (secret) {
            setEventSecret(secret);
            onEventUpload(secret);
        }
        setUploadSecretVisible(false);
    }

    const downloadEvent = () => {
        setDownloadSecretVisible(true);
    }
    const closeDownloadSecretModal = (secret, eventId) => {
        setEventSecret(secret);
        setDownloadSecretVisible(false);
        onEventDownload(eventId, secret);
    }
    const downloadEventImages = () => {
        if (eventSecret.length > 0) {
            onEventImageDownload(eventSecret);
        } else {
            setDownloadImageSecretVisible(true);
        }
    }
    const closeDownloadImageSecretModal = (secret) => {
        setEventSecret(secret);
        setDownloadImageSecretVisible(false);
        onEventImageDownload(secret);
    }

    const cloudDeleteEvent = () => {
        setDeleteModalVisible(true);
    }
    const cloudDeleteEventConfirmation = (wasConfirmed) => {
        if (wasConfirmed) {
            if (eventSecret.length > 0) {
                onCloudDeleteEvent(eventSecret);
            } else {
                setDeleteSecretVisible(true);
            }
        }
        setDeleteModalVisible(false);
    }
    const closeCloudDeleteEventSecretConfirmation = (secret) => {
        setEventSecret(secret);
        setDeleteSecretVisible(false);
        onCloudDeleteEvent(secret);
    }
    const targetDomainChangeEvent = (newKey) => {
        setTargetSelection(newKey);
    }

    return (
        <>
            <EventQRExportModal targetDomain={DEFAULT_TARGETS.find((item) => { return item.key === targetSelection; }).link} visible={showEventQRExportModal} onClose={closeQRExportModal} title="Event QR Exports" eventState={eventState} />
            <ConfirmationModal visible={resetModalVisible} title="Complete reset?" text="You will loose everything. Do you want to reset the event?" onClose={resetConfirmation} />
            <ConfirmationModal visible={deleteModalVisible} title="Delete Event?" text="Are you sure you want to delete the online event? All data will be removed." onClose={cloudDeleteEventConfirmation} />
            <ConfirmationModal visible={importModalVisible} title="Import data?" text="You will loose all the current data. Do you want to import the event?" onClose={importConfirmation} />
            <EventDetailModal eventState={modalEventState} onClose={modalCloseEvent} />
            <EventApiConfirmationModal visible={uploadSecretVisible} onClose={closeUploadSecretModal} />
            <EventApiConfirmationModal visible={downloadSecretVisible} provideEventIdInput={true} onClose={closeDownloadSecretModal} />
            <EventApiConfirmationModal visible={downloadImageSecretVisible} onClose={closeDownloadImageSecretModal} />
            <EventApiConfirmationModal visible={deleteSecretVisible} onClose={closeCloudDeleteEventSecretConfirmation} />
            <Container className="p-0">
                <Row>
                    <Col>
                        <EventActionBar targetSelection={targetSelection} onEditClick={editEventClick} onResetClick={resetEvent} onExportClick={exportEvent} onExportIdClick={exportEventId} onImportClick={importEvent} onQRCodeExport={openQRExportModal} onUploadClick={uploadEvent} onDownloadClick={downloadEvent} onDownloadImageClick={downloadEventImages} onCloudDeleteClick={cloudDeleteEvent} onTargetChange={targetDomainChangeEvent} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="fs-3">{eventState.getTitle()} (Version: {eventState.getVersion()})</div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {/* image src should be null, instead of "" because it might cause a re-render */}
                        <Image src={eventState.getImage() || null} rounded fluid />
                    </Col>
                    <Col className="fs-5">
                        {eventState.getDescription()}
                    </Col>
                </Row>
            </Container>
        </>
    );
}