import { React } from "react";
import PropTypes from "prop-types";
import { Badge, Card } from "react-bootstrap"
import { EventReward } from "../logic/event/eventReward";

RewardCard.propTypes = {
    lastChangeId: PropTypes.string.isRequired,
    reward: PropTypes.objectOf(EventReward).isRequired,
    wasCollected: PropTypes.bool,
    canBeCollected: PropTypes.bool,
}
export function RewardCard({ lastChangeId, reward, wasCollected = false, canBeCollected = false }) {
    const cardBorder = wasCollected ? "border border-primary" : "";

    const getFooter = () => {
        if (wasCollected) {
            return <Badge bg="primary" aria-description="Collected"><i className="bi bi-award-fill"></i> Collected</Badge>;
        }
        if (canBeCollected) {
            return <Badge bg="primary" aria-description="Can collect"><i className="bi bi-award"></i> Can be collected for {reward.getTargetPoints()} points</Badge>;
        }
        return <Badge bg="secondary" aria-description="Not available">Requires {reward.getTargetPoints()} points</Badge>;
    }
    const getNewBadge = () => {
        if (lastChangeId !== reward.getId()) {
            return <></>;
        }
        return <Badge bg="primary" aria-description="New"><i className="bi bi-stars"></i> NEW</Badge>;
    }

    return (
        <Card className={`mt-3 ${cardBorder}`}>
            <Card.Body>
                <Card.Title as="h4">
                    {reward.getTitle()}
                </Card.Title>
                <Card.Text>
                    {reward.getDescription()}
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <small>{getFooter()} {getNewBadge()}</small>
            </Card.Footer>
        </Card>
    )
}