import { React } from "react";
import PropTypes from "prop-types";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { CustomQRSVG } from "./CustomQRSVG";

QRExportModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    marginSize: PropTypes.number,
}
export function QRExportModal({ visible, link, title, onClose, marginSize = 3 }) {
    return (
        <Modal show={visible} onHide={() => { onClose() }}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col></Col>
                        <Col>
                            <CustomQRSVG marginSize={marginSize} value={link} title={title} />
                        </Col>
                        <Col></Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { onClose() }}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}